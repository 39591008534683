@import '../../styles/propertySets.css';

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
}

.imageLabel {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    max-width: 245px;
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: 58px;
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;
  line-height: 22px;
}

.submitButton {
  @media (--viewportLarge) {
    max-width: 228px;
  }
}

.cancelButton {
  /* margin-right: 15px; */

  @media (--viewportLarge) {
    max-width: 228px;
  }
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* margin-right: ; */
  margin-top: 0px;
}

@media (--viewportMedium) {
  .contentWrapper {
    padding-top: 0px;
    padding-bottom: 8px;
    margin-top: 0px;
  }
}

.label {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  padding: 11px 0 13px 0;
  margin: 0 18px 0 0;
}

@media (--viewportMedium) {
  .label {
    padding: 8px 0 16px 0;
  }
}

.inputsWrapper {
  display: flex;

  @media (--viewportMedium) {
    padding-bottom: 5px;
  }
}

.minPrice {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  width: 48px;
  text-align: center;
  border-bottom-color: var(--attentionColor);
  border-bottom-width: 3px;

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (--viewportMedium) {
    padding: 1px 0 4px 0;
  }
}

.maxPrice {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  width: 56px;
  text-align: center;
  border-bottom-color: var(--attentionColor);
  border-bottom-width: 3px;

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (--viewportMedium) {
    padding: 1px 0 4px 0;
  }
}

.priceSeparator {
  margin: 4px 8px 0 8px;
}

@media (--viewportMedium) {
  .priceSeparator {
    margin: 2px 8px 0 8px;
  }
}

.sliderWrapper {
  display: flex;
  padding: 17px 0 25px 0;
  max-width: 456px;
}

@media (--viewportMedium) {
  .sliderWrapper {
    padding: 16px 0 24px 0;
    margin-bottom: 24px;
  }
}

.projectTimelines {
  margin-bottom: 50px;
  max-width: 456px;
}

.countries {
  & legend {
    margin-bottom: 24px;
  }
}

.uploadDoc {
  margin-top: 0;

  @media(max-width: 1024px) {
    margin-top: 16px;
  }
}

.closer {
  position: relative;
  top: -6px;
  opacity: 0.5;
}

.closer:hover {
  opacity: 1;
  cursor: pointer;
}

.closer:before,
.closer:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 12px;
  width: 2px;
  background-color: #333;
}

.closer:before {
  transform: rotate(45deg);
}

.closer:after {
  transform: rotate(-45deg);
}


.oneRowTextInput {
  margin-bottom: 32px;
}

.rowInputWrapper {
  display: flex;
  justify-content: space-between;

  @media(max-width: 460px) {
    flex-wrap: wrap;
  }
}

.wrapperCurrencyFee {
  gap: 10px;
  display: flex;
  width: calc(50% - 9px);
  /* justify-content: space-between; */

  @media(max-width: 768px) {
    gap: 5px;
  }

  @media(max-width: 460px) {
    gap: 5px;
    width: 100%;
    justify-content: flex-start;
  }
}

.currencySelector {
  width: 90px;
  margin-bottom: 32px;
}

.paymentFeeInput {
  margin-bottom: 32px;

  @media(max-width: 460px) {
    width: 100%;
  }

  & input {
    padding-left: 10px;
  }
}

.halfRowTextInput {
  width: calc(50% - 9px);
  margin-bottom: 32px;

  @media(max-width: 460px) {
    width: 100%;
    justify-content: flex-start;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* max-height: 65px; */

  @media(max-width: 768px) {
    padding-bottom: 50px;
  }

  @media(max-width: 500px) {
    flex-wrap: wrap;
    gap: 10px;
  }

  & button {
    width: 200px;

    @media(max-width: 500px) {
      width: 100%;
    }
  }
}

.fieldDateInput {
  width: calc(50% - 9px);
  margin-bottom: 32px;

  @media(max-width: 460px) {
    width: 100%;
  }
}

.documentBox {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;

  & .documentImage {
    margin-right: 12px;
  }

  & .documentName {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.0875px;
    color: #3D3D3D;
  }

  & .documentDate {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.0875px;
    color: #B2B2B2;
  }
}

.removeFile {
  cursor: pointer;
  color: red;
  font-weight: 400;
}