.gigAppsPanel {
	width: 100%;
	margin: 2rem auto;
	max-width: 1180px;

	@media(max-width: 1024px) {
		/* margin: 3rem 0; */
	}

	& .gigAppsPanelSearch {
		display: flex;
		grid-gap: 20px;
		gap: 20px;

		@media(max-width: 1300px) {
			padding: 0 20px;
		}

		@media(max-width: 768px) {
			display: flex;
    	flex-direction: column;
		}
	}
}

.filterComponent {
	background: rgba(255, 255, 255, 0.25);
	border-radius: 5px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	border-right: 1px solid #E6E6E6;;
	margin-top: 1em;

	@media(max-width: 768px) {
		display: none;
	}

	& .headerWrapper {
		display: flex;
		justify-content: space-between;
	}

	& .filterBox {
		margin-bottom: 5px;

		@media(max-width: 768px) {
			margin-top: 10px;
		}
	}

	& h2 {
		margin: 0;
		font-size: 22px;
	}

	& .subHeading {
		margin: 0;
		font-size: 16px;
	}
}

.hideFilterComponent{
	border: none;
	display: block !important;
}

.hideFilterComponent{
	@media(max-width: 768px) {
		display: none;
	}
}

.headerContainer{
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 1em;
	
	& .headerLogo{
		width: 64px;
		height: 64px;
		border-radius: 5px;
		/* border: 1px solid orange; */

		& img{
			padding: 0 8px;
			object-fit: contain;
			width: 100%;
			height: 100%;
		}
	}

	& h1{
		font-size: 40px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1.25px;
		margin: 0;
	}
}

.leftBox {
	flex: 1;
}

.rightBox {
	flex: 2;
	padding-top: 5em;

	@media(max-width: 768px){
		padding-top: 0;
	}
}

.hideRightBox{
	display: none;
}

.searchBarWrapper{
	@media(max-width: 768px){
		display: flex;
		gap: 5px;
	}

	& .filterButton {
		border: 1px solid #b4b4b4;
		padding: 11px;
		width: 52px;
		height: 52px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;

		@media(min-width: 768px){
			display: none;
		}
	}

  & .selected{
    border-color: #0B96F5;
    background: rgba(11, 150, 245, 0.05);;
  }
}


.listRow {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px 10px;

	@media(max-width: 1024px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media(max-width: 460px) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.listBox {
	margin: 0;

	@media(max-width: 768px) {
		margin-top: 0px;
	}

	& li {
		cursor: pointer;
		font-size: 12px !important;
		display: block;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		line-height: 15px;
		padding: 4px 12px;
		border: 1px solid transparent;
		border-radius: 4px;
		color: #1d1c1d;

		&:hover {
			background-color: rgba(29, 155, 209, .1);
			border-color: #dcf0fb;
			text-decoration: underline;
		}
	}
}

.card {
	padding: 10px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	height: 100%;
	background-color: #0b96f51f;
	border-radius: 5px;
	border: 1px solid #0b96f500;

	&:hover {
		border: 1px solid #0b96f545;
	}

	& .descriptionMain {
		color: #1d1c1d;
		font-size: 12px;
		line-height: 12px;
		font-weight: 400;
		height: 44px;
		display: flex;
		align-items: center;
	}

	& .tags {
		display: flex;
		flex-wrap: wrap;
		gap: 5px;

		& span {
			padding: 0 5px;
			border: 1px solid #1d1c1d80;
			display: flex;
			border-radius: 4px;
			transition: all 80ms linear;
			align-items: center;
			height: 22px;
			font-size: 10px;
			cursor: pointer;
		}
	}
}

.cardHeaderRow {
	display: flex;
	margin-bottom: 7px;
	gap: 10px;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;

	& img {
		width: 45px;
		height: 45px;
		border-radius: 3px;
	}

	& h6 {
		font-size: 13px;
		line-height: 15px;
		margin: 0;
		width: 100%;
	}
}

.appLink {
	color: #1d1c1d;
	/* background: #ededed; */
	border-radius: 5px;

	&:hover {
		/* box-shadow: 0 1px 8px 0 rgba(0, 0, 0, .15); */
		text-decoration: none;
		/* transition: box-shadow .15s ease-out;
        border-radius: 6px; */
	}
}

.resetLink {
	color: #0B96F5;
	text-decoration: underline;
}

.filterGroup{
	margin-bottom: 20px;

	& .filterGroupLabel{
		font-size: 16px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: -0.25px;
	}

	& .filterItem{
		display: flex;
		gap: 10px;
	
		& input{
			width: 12px;
		}
	
		& label{
			font-size: 16px;
			font-weight: 500;
			line-height: 30px;
			letter-spacing: -0.25px;
			cursor: pointer;
		}
	}
}

.bottomWrapper{
	display: grid;
	grid-template-columns: 2fr 1fr 1fr;
	gap: 15px;
	margin-top: 25px;
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
  
	& .applyButton{
	  color: #0B96F5;
	}
}

.lovedAppsContainer{
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 30px;
	margin-bottom: 3em;

	@media(max-width: 768px){
		overflow-x: auto;
		gap: 15px;

    & .lovedAppBox{
      width: 300px;
    	padding: 30px 0 10px;
    }
	}

	& .lovedAppBox{
		border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
		text-decoration: none;
    color: unset;

		& .left{
			flex: 2;
		}

		& img{
			width: 98px;
			height: 98px;
			object-fit: cover;
			border-radius: 5px;
		}
	}
}

.recommendedAppsContainer{
	margin-bottom: 3em;
	& .recommendedApps{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		cursor: pointer;

		@media(max-width: 768px){
			gap: 0;
		}
	}
}
.gigAppBox{
  width: 370px;
  height: 100px;
  padding: 10px 0;
	padding-right: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  gap: 15px;
	text-decoration: none !important;
  color: unset;

  & img{
    width: 85px;
    height: 64px;
    border-radius: 4px;
  }
}
.groupHeading{
	font-size: 18px;
	font-weight: 700;
	line-height: 30px;
	margin-bottom: 0;
}
.appDescription{
	& h4, p{
		margin: 0;
	}
	& p{
		font-size: 12px;
		font-weight: 400;
		line-height: normal;
		letter-spacing: -0.25px;
	}
	& span{
		color: var(--Grey, #B2B2B2);
		font-size: 12px;
		font-weight: 600;
		line-height: normal;
		letter-spacing: -0.25px;
	}
}
.newAppBox{
	width: 370px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 15px;
	text-decoration: none !important;
  color: unset;

	@media(max-width: 768px){
		width: 300px;
	}

	& img{
		width: 100%;
		height: 236px;
		border-radius: 5px;
		background: #D9D9D9;

		@media(max-width: 768px){
			height: 200px;
		}
	}
}

.filteredApps{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 10px;
  padding: 0 5px;

  @media(max-width: 768px){
    display: flex;
    flex-direction: column;

    & .gigAppBox{
      width: auto;
    }
  }
}

.searchTermText{
  display: flex;
  align-items: center;
  gap: 10px;

  & h3{
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.25px;
    margin: 0;
  }
}
.selectedFiltersWrapper{
  & div{
    display: flex;
    justify-content: space-between;
    
    & h2{
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -0.25px;
      margin: 0;
    }

    & button{
      color: var(--Blue, #0B96F5);
      font-size: 12px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.25px;
    }
  }
  

  & .selectedFitlers{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin: 10px 0 10px;
    justify-content: unset;

    & label{
      border-radius: 2px;
      border: 1px solid #E6E6E6;
      background: #FFF;
      font-size: 14px;;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.25px;
      padding: 5px 10px;

      & svg{
        width: 12px;
        height: 12px;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
}

.csmSupportBanner{
	width: 100%;
	margin-bottom: 40px;
	&>div{
		margin-top: 0px;
	}
}